import type {
  CellClassFunc,
  Column,
  GridApi,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import get from 'lodash/get';
import set from 'lodash/set';
import type React from 'react';

interface ColumnDef {
  field: string;
  headerName: string | undefined;
  cellRenderer?:
    | ((
        params: Pick<ICellRendererParams, 'api' | 'colDef' | 'data' | 'value'>,
      ) => string)
    | 'agGroupCellRenderer';
  valueGetter?: (
    params: Pick<ValueGetterParams, 'api' | 'data' | 'node'>,
  ) => unknown;
  valueFormatter?: (
    params: Pick<ValueFormatterParams, 'api' | 'colDef' | 'data' | 'value'>,
  ) => unknown;
  cellClass?: string | string[] | CellClassFunc<unknown, unknown> | undefined;
  column: Column<unknown>;
}

function getColumnDef(column: Column<unknown>): ColumnDef {
  const {
    field,
    colId,
    headerName,
    cellRenderer,
    valueGetter,
    valueFormatter,
    cellClass,
  } = column.getColDef();

  return {
    field: (field || colId) as string,
    headerName,
    cellRenderer,
    valueGetter: valueGetter as ColumnDef['valueGetter'],
    valueFormatter: valueFormatter as ColumnDef['valueFormatter'],
    cellClass,
    column,
  };
}

function getColumnClasses(
  node: IRowNode<unknown>,
  index: number,
  col: ColumnDef,
  gridApi: GridApi,
): string | undefined {
  let className = col.cellClass;

  if (typeof col.cellClass === 'function') {
    className =
      col.cellClass({
        column: col.column,
        colDef: col.column.getColDef(),
        value: renderCell(gridApi, col, node, {
          useCellRenderer: false,
          useValueFormatter: false,
        }),
        data: node.data,
        node: node,
        api: gridApi,
        rowIndex: index,
        context: {},
      }) ?? undefined;
  }

  if (Array.isArray(className)) {
    className = className.join(' ');
  }

  return className as string | undefined;
}

function getColumnHeaders(columnDefs: ColumnDef[]) {
  const columnHeadersGrid: {
    field: string;
    headerName: string | undefined;
    colDef: ColumnDef | undefined;
    colSpan: number;
    rowSpan: number;
  }[][] = [];

  columnDefs.forEach((col, index) => {
    set(columnHeadersGrid, `0.${index}`, {
      field: col.field,
      headerName: col.headerName,
      colDef: col,
      colSpan: 1,
      rowSpan: 1,
    });

    let parent = col.column.getParent();
    let parentIndex = 1;

    while (parent) {
      const parentChildren = parent.getDisplayedChildren();

      const isTheSameAsRootColumn =
        parentChildren &&
        parentChildren.length === 1 &&
        parentChildren[0].isColumn &&
        parentChildren[0].getColId() === col.column.getColId();

      if (isTheSameAsRootColumn) {
        columnHeadersGrid[0][index].rowSpan += 1;
        const [cutColumn] = columnHeadersGrid[0].splice(index, 1);
        if (!columnHeadersGrid[parentIndex]) {
          columnHeadersGrid[parentIndex] = [];
        }
        columnHeadersGrid[parentIndex].push(cutColumn);
      } else {
        const providedColumnGroup = parent.getProvidedColumnGroup();
        const colDef = providedColumnGroup.getColGroupDef();
        // @ts-expect-error
        const field = colDef?.field ?? colDef?.colId;

        const matchingIndex = columnHeadersGrid[parentIndex]?.findIndex(
          (col) => col.field === field,
        );

        if (matchingIndex > -1) {
          columnHeadersGrid[parentIndex][matchingIndex].colSpan += 1;
        } else {
          if (!columnHeadersGrid[parentIndex]) {
            columnHeadersGrid[parentIndex] = [];
          }

          columnHeadersGrid[parentIndex].push({
            field,
            headerName: providedColumnGroup.getColGroupDef()?.headerName,
            colDef: undefined,
            colSpan: 1,
            rowSpan: 1,
          });
        }
      }

      parent = parent.getParent();
      parentIndex++;
    }
  });

  return columnHeadersGrid.reverse();
}

function renderCell(
  gridApi: GridApi,
  colDef: ColumnDef,
  rowNode: IRowNode<unknown>,
  { useCellRenderer = true, useValueFormatter = true } = {},
): string {
  let value = null;

  if (colDef.valueGetter) {
    value = colDef.valueGetter({
      data: rowNode.data,
      api: gridApi,
      node: rowNode,
    });
  } else {
    value = get(rowNode.data, colDef.field, '');
  }

  if (useCellRenderer && typeof colDef.cellRenderer === 'function') {
    const cellRenderer = colDef.cellRenderer({
      value: value,
      data: rowNode.data,
      // @ts-expect-error
      colDef,
    });

    return cellRenderer;
  }

  if (useValueFormatter && colDef.valueFormatter) {
    value = colDef.valueFormatter({
      value: value,
      data: rowNode.data,
      // @ts-expect-error
      colDef,
      api: gridApi,
    });
  }

  return value != null ? value.toString() : '';
}

function renderTable(
  gridApi: GridApi,
  columnDefs: ColumnDef[],
  rowNodes: IRowNode<unknown>[],
) {
  const filteredColumnDefs = columnDefs.filter((column) => {
    const isGroupCellRenderer = column.cellRenderer === 'agGroupCellRenderer';
    const isActionsColumn =
      column.field === 'actions' && column.headerName === undefined;

    return !isGroupCellRenderer && !isActionsColumn;
  });

  return (
    <table>
      <thead>
        {getColumnHeaders(filteredColumnDefs).map((row, rowIndex) => (
          // biome-ignore lint/suspicious/noArrayIndexKey: This is ok in this scenario since it's only rendered once
          <tr key={rowIndex}>
            {row.map((col) => {
              const cellClass = col?.colDef?.cellClass;
              let className =
                typeof cellClass !== 'function' ? cellClass : undefined;

              if (Array.isArray(cellClass)) {
                className = cellClass.join(' ');
              }

              return (
                <th
                  key={col.field}
                  colSpan={col.colSpan}
                  rowSpan={col.rowSpan}
                  className={className as string | undefined}
                >
                  {col.headerName}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {rowNodes.map((node, index) => {
          return (
            <tr key={node.id}>
              {filteredColumnDefs.map((col) => {
                const className = getColumnClasses(node, index, col, gridApi);

                return (
                  <td
                    className={className as string | undefined}
                    key={col.field}
                  >
                    {renderCell(gridApi, col, node)}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

function renderGroupedTables(
  gridApi: GridApi,
  columnDefs: ColumnDef[],
  rowNodes: IRowNode<unknown>[],
): React.ReactNode {
  const groupedRowNodes = rowNodes.filter((node) => node.group);

  const renderGroupTable = (
    node: IRowNode<unknown>,
    previousHeadersFields: string[] = [],
  ): React.ReactElement => {
    if (!node.leafGroup) {
      return (
        <div>
          {(node.childrenAfterGroup ?? []).map((childNode) =>
            renderGroupTable(
              childNode,
              node.field
                ? [...previousHeadersFields, node.field]
                : previousHeadersFields,
            ),
          )}
        </div>
      );
    }

    const allColumnDefs = gridApi.getColumnDefs() as ColumnDef[];
    const tableRowNodes = node.childrenAfterGroup ?? [];
    const currentGroupHeader = node.field
      ? `${renderCell(
          gridApi,
          allColumnDefs.find((col) => col.field === node.field)!,
          tableRowNodes[0],
          { useCellRenderer: false },
        )} (${node.allChildrenCount})`
      : '';

    const resolvedHeaders = previousHeadersFields.map((field) =>
      renderCell(
        gridApi,
        allColumnDefs.find((col) => col.field === field)!,
        tableRowNodes[0],
        { useCellRenderer: false },
      ),
    );

    return (
      <div>
        <h3 style={{ pageBreakBefore: 'always' }}>
          {[...resolvedHeaders, currentGroupHeader].join(' - ')}
        </h3>
        {renderTable(gridApi, columnDefs, tableRowNodes)}
      </div>
    );
  };

  return groupedRowNodes.map((node) => renderGroupTable(node));
}

export function getFullTableComponent(gridApi: GridApi) {
  const rowNodes: IRowNode<unknown>[] = [];
  gridApi.forEachNodeAfterFilterAndSort((node) => rowNodes.push(node));

  const columnDefs: ColumnDef[] = gridApi
    .getAllDisplayedColumns()!
    .map((col) => {
      return getColumnDef(col);
    });

  if (rowNodes[0]?.group) {
    return <div>{renderGroupedTables(gridApi, columnDefs, rowNodes)}</div>;
  }

  return renderTable(gridApi, columnDefs, rowNodes);
}
